@use '../../styles/variables' as *;
@use '../../styles/mixins';

.title {
  text-align: center;
}

.miniAppApplication {
  @include mixins.page-layout();
}
