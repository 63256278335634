@use '../../styles/colors';
@use '../../styles/mixins';

.labelText {
  margin: 15px 0 4px 0;
}

.inputWrapper {
  position: relative;
}

.input {
  @include mixins.input();
}

.textarea {
  @include mixins.input();
  resize: vertical;
  min-height: 80px;
}

.error {
  input, textarea {
    border-color: colors.$red;

    &:hover {
      border-color: colors.$red;
      box-shadow: 0 0 0 1px colors.$red;
    }
  }
}

.clearButton {
  all: unset;
  top: 9px;
  right: 8px;
  padding: 2px;
  cursor: pointer;
  font-size: 18px;
  color: colors.$grey;
  position: absolute;
}
