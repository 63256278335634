@use '../../styles/colors';

.sectionTitle {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}

.title {
  gap: 10px;
  display: flex;
  font-weight: 600;
  line-height: 16px;
  align-items: center;
}

.wrapper {
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid colors.$grey-300;
}
