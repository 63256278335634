@use '../../styles/variables' as *;
@use '../../styles/mixins';

.title {
  text-align: center;
}

.clientAppApplication {
  @include mixins.page-layout();
}

.goBackButtonWrapper {
  display: flex;
  justify-content: flex-end;
}
