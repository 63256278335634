@use '../../styles/colors';
@use '../../styles/variables' as *;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  background-color: rgba(11, 6, 4, 0.7);
}

:local .content {
  min-width: 300px;
  padding: 30px;
  border-radius: 4px;
  background-color: colors.$grey-800;
}
.content:focus {
  outline: none;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
}
