$font-primary: 'PPMori', sans-serif;

$breakpoint-xs: 320px;
$breakpoint-sm: 448px;
$breakpoint-md: 834px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

$spacing-sm: 0.75rem;
$spacing-md: 1.5rem;
$spacing-lg: 2.25rem;

$appHeaderHeight: 110px;
$appHeaderMarginBottom: 60px;