@use '../../styles/mixins';
@use '../../styles/colors';

.control {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.input {
  @include mixins.input();
}



.labelText {
  margin: 15px 0 4px 0;
}

.copyButton {
  font-size: 24px;
  padding: 8px 15px;
  color: colors.$grey-200;

  &:after {
    content:'';
    display:block;
    clear: both;
  }
}
