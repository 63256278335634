@use './variables' as *;
@use './colors';

@mixin page-layout {
  padding: 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    min-height: calc(100vh - ($appHeaderHeight + $appHeaderMarginBottom));
    margin: auto;
    overflow: auto;
    max-width: 750px;
  }
}

@mixin input {
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  border-radius: 8px;
  color: colors.$white;
  transition: all 0.2s;
  box-sizing: border-box;
  padding: 8px 30px 8px 12px;
  background-color: colors.$grey-500;
  border: 1px solid colors.$yellow-400;

  &::placeholder {
    color : colors.$grey;
  }

  &:hover {
    border-color: colors.$yellow;
    box-shadow: 0 0 0 1px colors.$yellow;
  }

  &:focus {
    outline: none;
    border-color: colors.$black;
    box-shadow: 0 0 0 2px colors.$blue-300, inset 0 0 0 2px colors.$grey-600;
  }
}