@use '../../styles/colors';

button {
  all: unset;
}

.label {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.root {
  background-color: white;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid colors.$grey;
  margin-right: 8px;

  &:not(:disabled):hover {
    background-color: colors.$yellow;
  }

  &:not(:disabled):focus {
    box-shadow: 0 0 0 2px colors.$black;
  }

  &:disabled {
    .indicator {
      background-color: colors.$yellow-500;
    }
  }
}

.indicator {
  width: 100%;
  height: 100%;
  background-color: colors.$yellow-300;
  &[data-state=indeterminate] {
    width:80%;
    height: 20%;
  }
}
