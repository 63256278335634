@use '../../../../styles/colors';

.title {
  text-align: center;
}

.modal {
  min-width: 560px;
  box-sizing: border-box;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 5px 0;
    justify-content: space-between;
  }
}

.buttonIcon {
  font-size: 15px;
  margin-right: 5px;
}

.copyApiKeyModal {
  max-width: 400px;

  .doneButtonWrapper {
    display: flex;
    justify-content: flex-end;
  }
}

.editButton {
  margin: 5px;
  cursor:pointer;
}