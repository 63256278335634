@use '../../styles/mixins';

.section {
  margin-bottom: 20px;
}

.userInfo {
  margin-bottom: 40px;
}

.dashboard {
  @include mixins.page-layout();
}

