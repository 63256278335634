@use '../../styles/colors';

.bold {
  font-weight: 600;
}

.small {
  font-size: 12px;
  line-height: 20px;
}

.large {
  font-size: 16px;
  line-height: 24px;
}

.disclaimer {
  color: colors.$grey;
}
