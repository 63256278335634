@use '../../styles/mixins';
@use '../../styles/colors';

.subscriptionResult {
  @include mixins.page-layout();
  text-align: center;
}

.content {
  margin: 20px;
  font-size: 16px;
}

.goBackButtonWrapper {
  display: flex;
  justify-content: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-right: 5px;
  font-size: 30px;
}

.errorIcon {
  color: colors.$red;
}

.successIcon {
  color: colors.$green;
}
