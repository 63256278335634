@use '../../styles/colors';

.thumbnail {
  width: 52px;
  height: 52px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: colors.$blue-600;
  text-transform: uppercase;
  background-color: colors.$yellow;

  img {
    line-height: 0;
    max-width: 100%;
  }
}