@use '../../styles/colors';
@use '../../styles/mixins';

.addOrganization {
  @include mixins.page-layout();
}

.content {
  display: flex;
  max-width: 294px;
  align-items: center;
  flex-direction: column;
  margin: 60px auto auto auto;
}

.contentVerifyOrg {
  display: flex;
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  margin: 60px auto auto auto;
}

.contentVerifyOrg form {
  width: 50%;
}

.headerSection {
  width: 470px;
  height: 91px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 23px;
}

.headerTitle {
  color: white;
  font-size: 30px;
  font-weight: 600;
  word-wrap: break-word;
}

.headerSubtitle {
  width: 426px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
}

.loginImage {
  margin-bottom: 3px;
  width: 109px;
  height: 131px;
}

.checkBox {
  display: flex;
  margin-top: 8px;
  align-items: center;
  gap: 1px;
}

.addButton {
  width: 40%;
  margin-top: 15px;
  margin-left: auto;
}

.verifyButton1 {
  width: 100%;
  margin-top: 15px;
}

.verifyButton {
  width: 100%;
  margin-top: 10px;
  background-color: #cf9b1e;
}

.plusIcon {
  font-size: 16px;
  margin-right: 8px;
}

.description {
  font-size: 20px;
  margin-top: 54px;
  line-height: 32px;
  margin-bottom: 24px;
}

.frame {
  align-items: center;
  border: 1px none;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  position: relative;
}

.frame .textWrapper {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 426px;
}

.a {
  padding: 0px;
  font-size: 13px;
  color: colors.$yellow;
  transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
  vertical-align: top;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.inputCheckbox {
  background-color: #363636;
  border: 1px solid;
  border-color: #cf9b1e;
  border-radius: 4px;
  height: 16px;
  width: 18px;
  appearance: none;
  position: relative;
  left: 0;
  top: 0;
  margin-right: 10px;
  vertical-align: top;
}

.inputCheckbox:checked {
  background-color: #cf9b1e;
}

.inputCheckbox:checked:after {
  content: '\2713';
  color: #363636;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkmark {
  vertical-align: top;
  font-size: 13px;
  margin-top: -5px;
  font-weight: 400;
  vertical-align: middle;
  color: #ffffff;
}
