/* footer.module.scss */
@use '../../styles/colors';
@use '../../styles/variables' as *;

.container {
  text-align: center;
  margin-top: 24px;
}
@media (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

.section {
  margin-bottom: 32px;
}

.content {
  padding: 12px;
  text-align: center;
}

.containerinner {
  max-width: 100%;
  padding: 0 16px;
}

.gridcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}

.griditem {
  flex: 1;
  margin-top: 12px;
}

.header {
  font-size: 5rem;
  font-weight: bold;
  color: hsl(218, 81%, 95%);
}

.yellowtext {
  color: colors.$yellow;
}

.leadtext {
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
}

.formcontainer {
  background-color: hsla(0, 0%, 15%, 0.9);
  padding: 48px;
  border-radius: 16px;
  max-width: 400px;
  margin: 0 auto;
}

.formheader {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;
}

.forminput {
  margin-bottom: 24px;
}

.inputfield {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 1rem;
  outline: none;
  transition: background-color 0.3s ease-in-out;
}

.inputfield::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.inputfield:focus {
  background-color: rgba(255, 255, 255, 0.4);
}

.subscribebutton {
  background-color: transparent;
  color: white;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.subscribebutton:hover {
  background-color: #555;
}

@media (max-width: 767px) {
  .grid-container {
    flex-direction: column;
    align-items: center;
  }
}
