@use '../../styles/colors';
@use '../../styles/variables' as *;

$delay: 0.2s;

.backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  position: fixed;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgba(11, 6, 4, 0.7);
}

.fadeIn {
  animation: fadeIn $delay ease-in-out;
}

.fadeOut {
  animation: fadeOut $delay ease-in-out;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
