@use '../../styles/colors';

.button {
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  box-sizing: border-box;

  &:disabled {
    cursor: default;
    background-color: colors.$grey;
  }
}

.medium {
  padding: 8px 24px;
}

.small {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 24px;
}

.primary {
  background-color: colors.$yellow;
  color: colors.$black;

  &:focus,
  &:hover {
    background-color: colors.$yellow-300;
  }
}

.danger {
  background-color: colors.$red;
  color: colors.$white;

  &:focus,
  &:hover {
    background-color: colors.$red;
  }
}

.outline {
  color: inherit;
  background-color: inherit;
  transition: background-color 0.1s ease-in-out;

  &.primary {
    box-shadow: 0 0 0 1px colors.$yellow;

    &:focus,
    &:hover {
      background-color: colors.$yellow-500;
      box-shadow: 0 0 0 1px colors.$yellow-300;
    }
  }

  &.danger {
    box-shadow: 0 0 0 1px colors.$red;

    &:focus,
    &:hover {
      background-color: colors.$red-500;
      box-shadow: 0 0 0 1px colors.$red-300;
    }
  }
}

.fullWidth {
  width: 100%;
}
