@use '../../styles/colors';
@use '../../styles/mixins';
@use '../../styles/variables' as *;

// $background1: url(../../images/object3.png) 0 550px / 400px no-repeat;
// $background2: url(../../images/object2.png) 0 200px / 370px no-repeat;
// $background3: url(../../images/object1.png) 30% 737px / 500px no-repeat;
// $background4: url(../../images/object4.png) right 400px / 450px no-repeat;

.signin {
  // padding: 0 30px;

  @media screen and (min-width: $breakpoint-lg) {
    min-height: calc(100vh - ($appHeaderHeight + $appHeaderMarginBottom));
    overflow: auto;
    // background: $background1, $background2, $background4;
    background-attachment: fixed;
  }
}

.loginButton {
  min-width: 132px;
  box-sizing: border-box;
  // increse button size
  padding: 16px 22px;
  // margin-top: 10px;
}

.welcomeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    margin: auto;
    max-width: 820px;
    // background: url(../../images/dancingPurple.png) top right / 200px no-repeat;
  }
}

.welcome {
  display: flex;
  align-items: center;
  flex-direction: column;
  // margin-top: 20px;
  margin-bottom: 30px;
  max-width: 350px;

  @media screen and (min-width: $breakpoint-md) {
    margin-bottom: 50px;
    margin-top: 20px;
  }

  h1 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 40px;
  }
}

.cards {
  display: grid;
  margin: 30px auto;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  padding: 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    max-width: 1175px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    justify-content: center;
  }
}