@use '../../../styles/colors';
@use '../../../styles/variables' as *;

.card {
  flex-basis: 90%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto auto auto;
  grid-auto-flow: row;
  grid-template-areas:
    "title apiStatus"
    "body body"
    "submissionStatus submissionStatus"
    "buttons buttons";
}

.date {
  font-size: 10px;
  margin-top: 5px;
}

.id {
  font-size: 10px;
}


@media screen and (min-width: $breakpoint-md) {
  .card {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
    "title apiStatus"
    "body body"
    "submissionStatus buttons";
  }
}

.title {
  grid-area: title;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.apiStatus {
  grid-area: apiStatus;
  text-align: right;
  font-weight: 600;

  .active {
    color: colors.$green-300;
  }

  .inactive {
    color: colors.$red-300;
  }
}

.body {
  grid-area: body;
}

.submissionStatus {
  font-size: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  grid-area: submissionStatus;

  .submissionStatusValue {
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .infoIcon {
    font-size: 16px;
  }

  .pending {
    color: colors.$blue-300;
  }

  .approved {
    color: colors.$green-300;
  }

  .rejected, .revoked {
    color: colors.$red-300;
  }
}

.buttons {
  display: flex;
  gap: 12px;
  grid-area: buttons;
  justify-content: flex-end;
}

.container {
  gap: 10px;
  display: flex;
}

