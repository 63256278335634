/* CommsCard.module.scss */
@use '../../styles/colors';
@use '../../styles/variables' as *;

.commscard {
  max-width: 20rem;
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: colors.$grey-800;
  border: 1px solid colors.$grey-400;

  img {
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: 0.75rem;
  }

  h5 {
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    color: colors.$white;
  }

  p {
    margin-bottom: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: colors.$grey-200;
    max-width: 18rem;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }

  &.withbutton {
    a {
      margin-top: 1rem;
      display: inline-flex;
      align-items: center;
      padding: 0.75rem 1rem;
      font-size: 0.875rem;
      font-weight: 600;
      text-align: center;
      color: white;
      border-radius: 0.375rem;

      svg {
        width: 0.875rem;
        height: 0.875rem;
        margin-left: 0.5rem;
      }
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 2rem;
  }
}
