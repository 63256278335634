@use '../../styles/colors';
@use '../../styles/variables' as *;

.appHeader {
  display: flex;
  padding: 25px 30px;
  border-bottom: 0.50px solid white;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  @media screen and (min-width: $breakpoint-md) {
    margin-bottom: $appHeaderMarginBottom;
  }
}

.logo img, .navigation a, .logoutButton {
  align-self: center;
}

.logo {
  margin-right: 10px;
  max-width: 106px;

  img {
    width: auto;
    max-width: 100%;
  }
}

.navigation {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  a {
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: colors.$white;
    transition: color 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: colors.$yellow;
    }
  }
}

.logoutButton {
  width: 122px;
  box-sizing: border-box;
  display: none;
}

@media screen and (min-width: $breakpoint-sm) {
  .logo {
    max-width: 213px;
  }

  .navigation {
    gap: 18px;
  }

  .logoutButton {
    display: flex;
  }
}
